import PendingBill from './PendingBill.vue'
import PendingBillCreate from './Create.vue'
import PendingBillEdit from './Create.vue'

export default [
    {
        path: '/pending_bill',
        name: 'PendingBill',
        component: PendingBill
    },
    {
        path: '/pending_bill/create',
        name: 'PendingBillCreate',
        component: PendingBillCreate
    },
    {
        path: '/pending_bill/edit/:id',
        name: 'PendingBillEdit',
        component: PendingBillEdit
    },

]
